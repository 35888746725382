html, body { }
@import "settings";
@import "mixins";

/*****************************************************************************
min-width: 1430px
*****************************************************************************/
@media only screen and (min-width: 1430px) {
	.container {
		max-width: 1430px;
	}

}

/*****************************************************************************
max-width: 1780px
*****************************************************************************/
@media only screen and (max-width: 1780px) {

	#main-slider .slider-main .slide .desc {
		margin-top: -45px;
	}

	#category-main a.link {
		margin-left: 0;
		display: flex;
		justify-content: flex-end;
	}

}

/*****************************************************************************
max-width: 1599px
*****************************************************************************/
@media only screen and (max-width: 1599px) {

	#main-slider .slider-main .slide .desc {
		h3 {
			font-size: 84px;
			line-height: 84px;
		}
		h2 {
			font-size: 140px;
			line-height: 130px;
		}
	}

	#category-main .inside .desc {
		left: -85px;
	}

	#category-selected .category-links {
		right: 15px;
	}

	#contact-page-map .city {
		width: 500px;
	}

}
/*****************************************************************************
max-width: 1429px
*****************************************************************************/
@media only screen and (max-width: 1429px) {

	.lang-box {
		width: calc(100% - 40px);
		padding: 54px 60px;
		.inside .right-col {
			padding-left: 50px;
			width: 360px;
		}
	}

	#additional-menu {
		width: 1140px;
		padding: 35px 52px;
		.submenu-links2 ul li {
			width: 25%;
		}
	}

	#Header .container {
		max-width: 100%;
	}

	#Header .header-middle .inside .right-col .form-place input[type="text"] {
		width: 420px;
	}

	#Header.sticky {
		#menu > ul > li > a {
			padding: 0 10px;
		}
		.header-middle {
			.menu-sticky {
				#menu {
					display: none;
				}
			}
			.inside .right-col .form-place.active {
				right: 0;
				top: 75px;
				margin-right: 0;
			}
		}
	}

	#menu > ul > li .level-links {
		width: 1140px;
		padding: 35px 52px;
		.level-links__inside {
			.submenu-links {
				width: 240px;
				&.big {
					width: 240px;
				}
				ul li a {
					padding: 5px 0;
				}
			}
			.submenu-photo {
				height: 264px;
				a .desc {
					h4 {
						font-size: 17px;
					}
				}
				&.narrow {
					a .desc {
						top: 50px;
					}
				}
			}
		}
	}

	#main-slider {
		.slider-main .slide {
			.content-wrapper {
				width: 1110px;
			}
			.desc {
				h3 {
					margin-bottom: 8px;
					font-size: 72px;
					line-height: 74px;
				}
				h2 {
					font-size: 120px;
					line-height: 110px;
				}
				p {
					font-size: 25px;
				}
			}
		}
		.bx-controls {
			bottom: 40px;
			.bx-pager {
				margin-left: 580px;
			}
		}
	}

	#services-home {
		display: none;
	}

	#specials-home .inside {
		.left-col .photo-product {
			max-width: 420px;
		}
		.right-col .inverted {
			display: none;
		}
	}

	#filter-home {
		margin-bottom: 80px;
	}

	#category-main {
		height: 843px;
		&:before {
			height: 843px;
		}
		.inside .desc {
			left: -40px;
			top: 115px;
			width: 540px;
			height: 420px;
			padding: 80px 85px 0;
		}
	}

	#inspirations-home .inside {
		.additional-photos {
			display: none;
		}
		.few-photos {
			width: 540px;
			a.insta-photo {
				width: 260px;
				height: 260px;
			}
		}
		.one-photo {
			width: 550px;
			a.insta-photo {
				width: 550px;
				height: 550px;
			}
		}
	}

	#blog-home .inside .items .item {
	  width: 360px;
		a {
			.photo {
				height: 239px;
			}
			.desc {
				padding: 21px 30px 0;
				h3 {
					font-size: 20px;
				}
			}
		}
	}

	#category-selected {
		height: 800px;
		&:before {
			height: 800px;
		}
		.inside {
			.left-col {
				.photo {
					width: 820px;
					height: 560px;
				}
				a.link {
					margin-left: 0;
				}
			}
			.right-col {
				padding: 128px 0 0 56px;
				p {
					margin-bottom: 20px;
					font-size: 18px;
					line-height: 30px;
				}
			}
		}
	}

	#shipping-payment .inside {
		p br {
			display: none;
		}
		.logos {
			.photo {
				margin-right: 30px;
			}
		}
		.left-col {
			width: 500px;
			.logos .photo {
				max-width: 130px;
			}
		}
		.right-col {
			padding-left: 50px;
		}
	}

	.products-slider .slick-arrow {
		&.slick-prev {
				left: -30px;
		}
		&.slick-next {
				right: -30px;
		}
	}

	#Footer {
		.top-footer {
			.left-col h4 {
				font-size: 18px;
			}
			.right-col .item {
				padding-right: 40px;
			}
		}
		.category-links .inside ul li a {
			font-size: 14px;
		}
	}

	.shopping-cart__subpage .inside {
		flex-wrap: wrap;
		.left-col {
			width: 100%;
			margin-right: 0;
		}
		.right-col {
			width: 100%;
			position: inherit !important;
			top: inherit !important;
			h4 {
				padding-bottom: 10px;
				border-bottom: 1px solid #ddd;
				font-size: 17px;
			}
		}
	}

	.products__subpage .inside .right-col {
		width: 830px;
	}
	.filtration-results {
		width: 620px;
	}

	.customer-panel__subpage .inside {
		.left-col {
			width: 350px;
			padding: 55px 30px 44px;
		}
		.right-col {
			width: 740px;
		}
	}

	.order-list {
		p {
			font-size: 14px;
			.hour {
				margin-left: 10px;
			}
		}
		.number {
			padding: 0 15px;
		}
		.date {
			padding: 0 15px;
		}
		.price {
			padding: 0 15px;
		}
		.more {
			padding: 0 15px;
		}
	}

	.order-details .cart-table .cart-row {
		.cart-width-2 {
	  	width: 56%;
		}
		.cart-width-4 {
			width: 22%;
		}
	}

	.changed-data {
		.item .item-half, .link-other-address p {
		  width: 360px;
		}
	}

	.shopping-cart__subpage.step-2 {
		.changed-data {
			.item .item-half {
				width: 49%;
			}
			p {
				width: 49%;
			}
		}
		.form-simple .form-check-input {
			margin-top: 5px;
		}
	}

	#contact-page-map {
		.city {
			height: 450px;
		}
		#map {
			height: 450px;
			margin-left: 20px;
		}
	}

	.subpage-contact .inside .right-col {
		margin-left: 50px;
		width: 470px;
		padding: 50px 42px;
	}

	.subpage-blog-details {
		.blog-details {
		  width: 67%;
			.blog-details__header {
				margin-bottom: 30px;
				h3 {
					padding-bottom: 20px;
					font-size: 22px;
					line-height: 26px;
				}
			}
		}
		.blog-sidebar {
		  width: 30%;
			aside {
				margin-bottom: 20px;
				h3 {
					margin-bottom: 30px;
					padding-bottom: 15px;
					font-size: 18px;
					line-height: 21px;
				}
			}
			.widget-latest-news .items .item a .desc {
			  width: 230px;
				p.title {
					font-size: 14px;
					line-height: 17px;
				}
			}
		}
	}

	.subpage-gallery .inside .item a .photo {
		width: 234px;
		height: 234px;
	}

	.product-inside .top {
		.left-col {
			width: 610px;
		}
		.right-col {
			margin-left: 40px;
			width: 460px;
			h2.category {
				font-size: 26px;
			}
			h3.name {
				font-size: 20px;
			}
			.rating {
				margin-top: 20px;
			}
			.variants-color {
				margin-top: 30px;
			}
			.to-cart .btn-turquoise {
				min-width: 244px;
			}
			.info-box {
				margin-top: 40px;
			}
			.detailed {
				padding-left: 30px;
			}
		}
	}

	.choose-size {
		margin-top: 40px;
	}

}

/*****************************************************************************
max-width: 1199px
*****************************************************************************/
@media only screen and (max-width: 1199px) {

	.listing-sort {
		margin-left: 10px;
	}

	.lang-box {
		padding: 50px 50px 40px;
		.language-list li {
			width: 49%;
		}
		.inside .right-col .item-select {
			margin: 40px 0 25px;
		}
	}

	#additional-menu {
		width: 930px;
		padding: 35px 30px;
		.submenu-links2 ul li {
			width: 33.3%;
		}
	}

	#Header {
		.header-middle {
			.inside .right-col {
				.form-place input[type="text"] {
					width: 330px;
					padding: 0 18px;
				}
				.actions-shop div {
					margin-left: 25px;
				}
			}
			.menu-sticky #menu {
				display: none;
			}
		}
		.header-bottom .inside .right-col .call-us {
			display: none;
		}
	}
	#Header.sticky .header-middle .inside .right-col .form-place.active {
		input[type="text"] {
			width: 410px;
		}
	}

	#menu > ul > li .level-links {
	  width: 930px;
	  padding: 35px 30px;
		.level-links__inside .submenu-photo {
			height: 221px;
			&.wide {
				margin-left: 15px;
			}
		}
	}

	#main-slider {
		.slider-main .slide {
			.content-wrapper {
				width: 930px;
			}
			.desc {
				h3 {
					font-size: 62px;
					line-height: 72px;
				}
				h2 {
					font-size: 90px;
					line-height: 80px;
				}
				p {
					font-size: 22px;
				}
			}
		}
		.bx-controls {
			bottom: 40px;
			.bx-pager {
				margin-left: 490px;
			}
		}
	}

	#bestsellers-home {
		margin-top: 50px;
		.products-slider {
			margin-top: 40px;
		}
	}

	#new-products-home {
		margin-bottom: 70px;
		.products-slider {
	  	margin-top: 40px;
		}
	}

	#specials-home {
		margin-bottom: 70px;
		padding: 70px 0;
		.inside {
			height: 460px;
			.left-col {
				width: 605px;
				margin-right: 25px;
				padding: 50px 0 0 40px;
				.photo-product {
					max-width: 330px;
				}
				h3 {
					margin-bottom: 30px;
					font-size: 42px;
					line-height: 42px;
				}
				.time .item {
					margin-right: 10px;
					width: 70px;
					height: 86px;
					p.number {
						font-size: 28px;
						line-height: 28px;
					}
				}
			}
			.right-col {
				width: 300px;
				background-position: 0 bottom;
				h3 {
					font-size: 34px;
					line-height: 30px;
					font-weight: 600;
				}
			}
		}
	}

	#filter-home {
		margin-bottom: 50px;
	}

	#category-main {
		height: 736px;
		margin-bottom: 80px;
		&:before {
			height: 736px;
			width: 350px;
		}
		.inside .desc {
			left: -20px;
			top: 91px;
			width: 440px;
			height: 360px;
			padding: 60px 50px 0;
			h3 {
				font-size: 28px;
				line-height: 32px;
			}
			p {
				font-size: 18px;
				line-height: 34px;
			}
		}
		&:after {
			top: 22px;
		}
	}

	#inspirations-home {
		margin-bottom: 70px;
		.insta-info {
			margin-left: 20px;
			p {
				&.small {
					display: none;
				}
				&.big {
					font-size: 16px;
					line-height: 16px;
				}
			}
		}
		.inside {
			.items {
				margin-top: 50px;
			}
			.few-photos {
				width: 450px;
				a.insta-photo {
					width: 215px;
					height: 215px;
				}
			}
			.one-photo {
				width: 460px;
				a.insta-photo {
					width: 460px;
					height: 460px;
				}
			}
		}
	}

	#blog-home .inside .items .item {
	  width: 300px;
		a {
			.photo {
				height: 199px;
			}
			.desc {
				padding: 15px 25px 0;
				h3 {
					font-size: 20px;
				}
			}
		}
	}

	#category-selected {
		margin-bottom: 60px;
		height: 740px;
		&:before {
			width: 350px;
			height: 740px;
		}
		.inside {
			.left-col {
				.photo {
					width: 750px;
					height: 512px;
				}
			}
			.right-col {
				padding: 95px 0 0 56px;
				p {
					margin-bottom: 15px;
					font-size: 16px;
					line-height: 28px;
				}
			}
		}
	}

	#delivery-info .inside .items .item {
		h5 {
			font-size: 18px;
		}
		p {
			font-size: 14px;
			br {
				display: none;
			}
		}
	}

	#company-home {
		margin-top: 60px;
		.inside {
			padding-left: 170px;
			background-size: 150px;
		}
	}

	#shipping-payment .inside {
		min-height: 140px;
		.left-col {
			width: 50%;
			background-position: right center;
			.logos .photo {
				max-width: 100px;
			}
		}
		.right-col {
			flex: inherit;
			width: 50%;
			padding-left: 25px;
		}
		p {
			font-size: 14px;
		}
	}

	#Footer {
		.top-footer {
			.inside {
				flex-wrap: wrap;
			}
			.left-col {
				width: 100%;
				margin-bottom: 40px;
				display: flex;
				.tel-box {
					padding: 68px 0 0 130px;
				}
			}
			.right-col {
				width: 100%;
				justify-content: space-between;
			}
		}
		.category-links .inside ul li {
			width: 33.3%;
			text-align: center;
			a {
				padding: 5px 10px;
			}
		}
		.signature .inside {
			padding-bottom: 40px;
		}
	}

	.shopping-cart__subpage {
		margin: 45px 0 30px;
	}

	.products__subpage {
		.inside {
			.left-col {
				width: 245px;
			}
			.right-col {
				width: 650px;
			}
		}
		.products-list__subpage .item {
			width: 205px;
			a {
				.photo {
					height: 290px;
				}
			}
		}
	}

	.products-slider__subpage {
		padding: 20px 40px 8px;
		.products-slider {
			.item {
				width: 196px;
				a {
					margin-right: 20px;
					.photo {
						height: 243px;
					}
				}
			}
			.slick-arrow.slick-prev {
				width: 30px;
				height: 30px;
				left: -34px;
				top: 113px;
			}
			.slick-arrow.slick-next {
				width: 30px;
				height: 30px;
				right: -34px;
				top: 113px;
			}
		}
	}

	.filtration-results {
		width: 460px;
	}
	ul.filter-checkbox-color li {
		margin: 0 15px 15px 0;
	}
	.input-line input[type="text"] {
		width: 110px;
	}
	.product-list-filtering h5 {
		font-size: 15px;
	}

	.blog__subpage #blog-home {
		  margin-bottom: 30px;
		.inside {
			h2 {
				margin-bottom: 30px;
				font-size: 28px;
			}
			.items .item {
				margin-bottom: 40px;
				a .desc {
					padding: 10px 20px 0;
					time {
						margin-bottom: 5px;
						font-size: 14px;
					}
					h3 {
						font-size: 18px;
					}
				}
			}
		}
	}

	.customer-panel__subpage .inside {
		.left-col {
			width: 300px;
			padding: 40px 22px 35px;
		}
		.right-col {
			width: 604px;
			h3 {
				margin-bottom: 20px;
				padding-bottom: 10px;
				font-size: 18px;
				line-height: 22px;
			}
			.top h2 {
				font-size: 18px;
				line-height: 22px;
			}
		}
	}

	.order-list {
		p {
			font-size: 14px;
			.hour {
				display: block;
				margin-left: 0;
				font-size: 12px;
			}
		}
		.number {
			width: 20%;
			padding: 0 10px;
		}
		.date {
			width: 26%;
			padding: 0 10px;
		}
		.price {
			padding: 0 10px;
		}
		.more {
			padding: 0 10px;
		}
	}

	.order-details {
		.payment {
			flex-wrap: wrap;
			.text p {
				font-size: 15px;
				a.pay {
					display: inline-block;
				}
			}
			.price {
				width: 100%;
				margin-top: 10px;
			}
		}
		.address .item p {
			font-size: 15px;
			line-height: 23px;
		}
	}

	.changed-data {
		.item {
			flex-wrap: wrap;
			.item-half {
				width: 100%;
			}
		}
		.link-other-address p {
			width: 100%;
		}
		h5.space {
			display: none;
		}
	}
	.vat-eu {
		margin-bottom: 20px;
	}

	.shopping-cart__subpage.step-2 {
		.top {
			flex-wrap: wrap;
		}
		.changed-data .item .item-half {
			width: 100%;
		}
	}

	.payment-delivery {
		.option .payment-details {
			margin-left: 42px;
		}
		.item {
			flex-wrap: wrap;
			.radio {
				width: 100%;
				label {
					font-size: 16px;
				}
			}
			.turquoise {
				margin: 10px 0 0 40px;
			}
			p.price {
				width: 100%;
				margin: 15px 0 0 40px;
			}
		}
		.delivery-country {
			flex-wrap: wrap;
			justify-content: flex-end;
			.text {
				margin-bottom: 5px;
				width: 100%;
				font-size: 16px;
			}
		}
	}

	#contact-page-map {
		.city {
			width: 400px;
			height: 400px;
		}
		#map {
			height: 400px;
			margin-left: 20px;
		}
	}

	.subpage-contact .inside {
		.left-col {
			h3 {
				font-size: 20px;
			}
		}
		.right-col {
			margin-left: 30px;
			width: 400px;
			padding: 50px 30px;
			h4 {
				font-size: 17px;
			}
			h5 {
				font-size: 17px;
			}
		}
	}

	.subpage-blog-details {
		.blog-details {
			width: 67%;
			.blog-details__header {
				margin-bottom: 30px;
				h3 {
					padding-bottom: 15px;
					font-size: 18px;
					line-height: 22px;
				}
			}
		}
		.blog-sidebar {
			width: 30%;
			margin-top: 7px;
			aside {
				margin-bottom: 20px;
				h3 {
					margin-bottom: 30px;
					padding-bottom: 10px;
					font-size: 16px;
					line-height: 20px;
				}
			}
			.widget-latest-news .items .item a .desc {
				width: 177px;
				p.date {
					margin-bottom: 4px;
				}
				p.title {
					font-size: 13px;
					line-height: 15px;
				}
			}
		}
	}

	.subpage-gallery .inside {
		justify-content: space-between;
		.item {
			margin: 0 0 15px;
			a .photo {
				width: 300px;
				height: 300px;
			}
		}
	}

	.product-inside .product-reviews .inside {
		flex-wrap: wrap;
		.left-col {
			width: 100%;
			flex: inherit;
			margin-bottom: 30px;
		}
		.right-col {
			margin-left: 0;
			width: 100%;
		}
	}

	.product-inside .top {
		.left-col {
			width: 460px
		}
		.right-col {
			margin-left: 30px;
			width: 440px;
			h2.category {
				font-size: 22px;
			}
			h3.name {
				font-size: 18px;
				line-height: 24px;
			}
			.rating {
				margin-top: 15px;
			}
			.to-cart .btn-turquoise {
				min-width: 236px;
			}
		}
	}
	.choose-size {
		margin-top: 25px;
	}

}

/*****************************************************************************
max-width: 991px
*****************************************************************************/
@media only screen and (max-width: 991px) {

	.lang-box {
		top: 30px;
		padding: 40px 40px 30px;
		max-height: 550px;
		.inside {
			flex-wrap: wrap;
			.left-col {
				border-right: 0;
				margin-bottom: 20px;
			}
			.right-col {
				width: 100%;
				padding-left: 0;
				.item-select {
					margin: 30px 0 20px;
				}
			}
			h5 {
				margin-bottom: 20px;
			}
		}
	}

	#additional-menu {
		width: 100%;
		height: 100%;
		top: 0;
		padding: 0;
		background: #f8f8f8;
		.additional-menu__responsive {
			display: block;
		}
		.submenu-links-normal {
			display: none;
		}
	}
	body.sticky #additional-menu {
	  top: 0;
	}

	#Header {
		height: 128px;
		.header-middle {
			.inside .right-col {
				.form-place {
					display: none;
					margin-right: 15px;
					&.active {
						display: block;
						position: absolute;
						top: 136px;
						right: 0;
						z-index: 9;
					}
					input[type="text"] {
						width: 370px;
					}
				}
				.actions-shop div {
					margin-left: 20px;
					&.search-link {
						display: block;
						opacity: 1;
					}
					&:first-child {
						margin-left: 20px;
					}
				}
			}
			.menu-sticky {
				display: flex;
				align-items: center;
			}
		}
		.header-bottom {
			display: none;
		}
	}

	#Header.sticky .header-middle {
		.inside .right-col .form-place.active input[type="text"] {
			width: 370px;
		}
		.menu-sticky {
			margin-right: 0;
		}
	}

	#Content {
	  padding-top: 128px;
	}

	#main-slider {
		.slider-main .slide {
			.content-wrapper {
				width: 690px;
			}
			.desc {
				margin-top: -40px;
				h3 {
					font-size: 46px;
					line-height: 52px;
				}
				h2 {
					font-size: 68px;
					line-height: 58px;
				}
				p {
					font-size: 20px;
				}
			}
		}
		.bx-controls {
			bottom: 30px;
			.bx-pager {
				margin-left: 380px;
			}
		}
	}

	#specials-home {
		margin-bottom: 50px;
		padding: 50px 0;
		.inside {
			height: 498px;
			.left-col {
				width: 405px;
				margin-right: 15px;
				padding: 35px 0 0 30px;
				.photo-product {
					max-width: 275px;
					max-height: 240px;
					overflow: hidden;
				}
				h3 {
					margin-bottom: 20px;
					font-size: 32px;
					line-height: 32px;
				}
				p.price {
					margin-bottom: 20px;
					strong {
						font-size: 26px;
					}
				}
				.time {
					margin-bottom: 15px;
					.item {
						margin-right: 10px;
						width: 62px;
						height: 76px;
						p.number {
							margin-bottom: 2px;
							font-size: 26px;
							line-height: 26px;
						}
					}
				}
				.btn-transparent-green {
					position: absolute;
					bottom: 30px;
					padding: 0 15px;
					line-height: 42px;
				}
			}
			.right-col {
				width: 270px;
				h3 {
					font-size: 28px;
					line-height: 26px;
					font-weight: 600;
				}
			}
		}
	}

	#filter-home {
		h4 {
			font-size: 17px;
			&:after {
				width: calc(100% + 40px);
			}
		}
		.filter-home-inside {
			margin-bottom: 25px;
		}
	}

	#category-main {
		height: 540px;
		margin-bottom: 60px;
		padding-top: 70px;
		&:before {
			height: 540px;
			width: 280px;
		}
		.inside {
			margin-bottom: 30px;
			.desc {
				left: -20px;
				top: 52px;
				width: 360px;
				height: 280px;
				padding: 40px 30px 0;
				h3 {
					font-size: 26px;
					line-height: 32px;
				}
				p {
					font-size: 16px;
					line-height: 28px;
				}
			}
		}
		&:after {
			display: none;
		}
	}

	#inspirations-home {
		margin-bottom: 50px;
		.insta-info {
			margin-left: 20px;
			p {
				&.small {
					display: none;
				}
				&.big {
					font-size: 16px;
					line-height: 16px;
				}
			}
		}
		.inside {
			.items {
				margin-top: 30px;
			}
			.few-photos {
				width: 339px;
				a.insta-photo {
					width: 164px;
					height: 164px;
				}
			}
			.one-photo {
				width: 340px;
				a.insta-photo {
					width: 340px;
					height: 340px;
				}
			}
		}
	}

	#blog-home .inside {
		padding-top: 40px;
		.items {
			margin-bottom: 20px;
			.item {
			  width: 222px;
				a {
					.photo {
						height: 148px;
					}
					.desc {
						padding: 15px 20px 0;
						time {
							font-size: 15px;
						}
						h3 {
							font-size: 18px;
						}
					}
				}
			}
		}
	}

	#category-selected {
		margin-bottom: 60px;
		height: inherit;
		&:before {
			width: 280px;
			height: 615px;
		}
		.category-links {
			top: 0;
		}
		.inside {
			padding-top: 40px;
			.left-col {
				.photo {
					width: 595px;
					height: 406px;
				}
			}
			.right-col {
				padding: 70px 0 0 25px;
				h6 {
					font-size: 16px;
				}
				p {
					margin-bottom: 15px;
					font-size: 15px;
					line-height: 24px;
				}
			}
		}
	}

	#delivery-info {
		padding: 45px 0;
		.inside .items .item {
			padding: 55px 0 0 0;
			background-position: center top !important;
			text-align: center;
			width: 32%;
			margin-right: 0;
			h5 {
				font-size: 17px;
			}
		}
	}

	#company-home {
		margin-top: 40px;
		.inside {
			background: none;
			padding-left: 0;
		}
	}

	#shipping-payment .inside {
		min-height: 140px;
		.left-col {
			.logos .photo {
				max-width: 85px;
				margin-right: 20px;
				&:last-child {
					margin-right: 0;
				}
			}
			p {
				margin-bottom: 50px;
			}
		}
		.right-col {
			padding-left: 20px;
			.logos .photo {
				margin-right: 20px;
			}
		}
		p {
			font-size: 14px;
			line-height: 18px;
		}
	}

	.shopping-cart__subpage {
	  margin: 35px 0 30px;
		.inside {
			.left-col .under-cart {
				margin-bottom: 20px;
				ul.simply-link {
					width: 100%;
					order: 3;
					margin-top: 30px;
					li:last-child {
						margin-right: 0;
					}
				}
				.whole-value {
					width: 100%;
					order: 1;
				}
				.buttons {
					order: 2;
				}
			}
			.right-col {
				.products-list__subpage .item {
					width: 160px;
					a {
						.photo {
							height: 227px;
						}
						.desc {
							p.price {
								font-size: 15px;
								strong {
									font-size: 17px;
								}
							}
						}
					}
				}
			}
		}
	}

	.cart-table .cart-row {
		.cart-width-1 {
			padding: 0;
			width: 7%;
		}
		.cart-width-4 {
			width: 19%;
		}
		.remove a {
			width: 30px;
			height: 30px;
			background-size: 100%;
		}
	}

	.products__subpage {
		h2.h2-sb {
			font-size: 26px;
			line-height: 28px;
		}
		.inside {
			.left-col {
				width: 230px;
			}
			.right-col {
			  width: 430px;
			}
		}
	}

	.products-slider__subpage {
		h3 {
			font-size: 18px;
		}
		 .products-slider .item {
			 width: 185px;
			 a .photo {
				 height: 234px;
			 }
		 }
	}

	.input-line input[type="text"] {
	  width: 102px;
	}

	.additional-text-product {
		h3 {
			margin-bottom: 17px;
			font-size: 18px;
			line-height: 22px;
		}
		p {
			font-size: 14px;
			line-height: 26px;
		}
	}

	.how-many {
		top: 0;
	}
	.filtration-results {
		width: 100%;
	}

	.listing-sort {
		.bootstrap-select.form-control {
			height: 35px;
		}
		.bootstrap-select {
			.btn {
				height: 35px;
			}
			.dropdown-menu {
			}
		}
	}

	.comparison__subpage .inside {
		overflow: scroll;
	}
	.comparison {
		width: 690px;
		&.three-products {
			width: 860px;
		}
		.comparison__header {
			.comparison__header--label {
				width: 150px;
			 }
			.comparison__header--item {
				&.is-two, &.is-three {
					width: 234px !important;
				}
			}
		}
		.comparison__content .comparison__row {
			.comparison__row--label {
				width: 150px;
			}
			.comparison__row--item {
				&.is-two, &.is-three {
					width: 234px !important;
				}
			}
		}
	}

	.blog__subpage #blog-home .inside {
		h2 {
			font-size: 26px;
		}
		.items .item {
			width: 338px;
			a {
				.photo {
					height: 225px;
				}
			}
		}
	}

	.subpage .text-line h2.h2-sb {
		font-size: 26px;
		line-height: 28px;
	}

	.inspiration__subpage #inspirations-home .inside {
		h6.h6-main {
			margin-bottom: 30px;
		}
		.items {
		  margin-top: 15px;
		}
	}

	.customer-panel__subpage .inside {
		.left-col {
			width: 260px;
			padding: 25px 20px 35px;
			.top {
				padding: 68px 0 0;
				background-position: center 0;
				text-align: center;
				h5 {
					font-size: 15px;
					line-height: 19px;
				}
				p {
					font-size: 12px;
				}
			}
			.options .item {
				a {
					padding: 20px 10px 20px;
					font-size: 14px;
					line-height: 18px;
				}
				.counter-number {
					top: 20px;
					right: 0;
				}
			}
		}
		.right-col {
			width: 410px;
			h3 {
				margin-bottom: 20px;
				padding-bottom: 10px;
				font-size: 16px;
				line-height: 20px;
			}
			p.intro {
				font-size: 14px;
				line-height: 18px;
				font-weight: 300;
			}
			.top {
				flex-wrap: wrap;
				padding-bottom: 10px;
				h2 {
					margin-bottom: 5px;
				}
				p.date {
					margin-left: 0;
				}
			}
		}
	}

	.order-list {
		margin-top: 20px;
		p {
			margin-bottom: 5px;
			font-size: 14px;
			.hour {
				display: inline;
				margin-left: 5px;
				font-size: 14px;
			}
			&.resp {
				display: block;
				margin-right: 10px;
			}
		}
		.row-head {
			display: none;
		}
		.row-order {
			flex-wrap: wrap;
			align-items: flex-start;
			padding: 15px 0;
			& > div {
				display: flex;
			}
		}
		.number {
			width: 100%;
			padding: 0 10px;
		}
		.date {
			width: 100%;
			padding: 0 10px;
		}
		.price {
			width: 100%;
			padding: 0 10px;
		}
		.more {
			width: 100%;
			padding: 10px 10px 0;
			text-align: left;
		}
	}

	.order-details {
		.payment .text p a.pay {
			display: inline-block;
			margin-left: 0;
		}
		.whole-value p.number {
			margin-left: 30px;
		}
		.address .item {
			width: 100%;
		}
	}

	.changed-password {
	  width: 100%;
	}

	.client-type {
		margin: 15px 0 0;
		width: 100%;
		& > div:first-child {
			margin-left: -13px;
		}
	}

	.changed-data .other-address {
		margin-top: 20px;
	}

	#contact-page-map {
		.city {
			display: none;
		}
		#map {
			margin-left: 0;
		}
	}

	.subpage-contact {
		margin: 50px 0;
		.inside {
			flex-wrap: wrap;
			.left-col {
				order: 2;
				width: 100%;
				flex: inherit;
				h3 {
					font-size: 20px;
				}
			}
			.right-col {
				order: 1;
				margin-left: 0;
				margin-bottom: 30px;
				width: 100%;
				padding: 50px 50px;
			}
		}
	}

	.subpage-blog-details {
		flex-wrap: wrap;
		.blog-details {
			width: 100%;
		}
		.blog-sidebar {
			width: 100%;
			margin-top: 30px;
			.widget-latest-news .items .item a .desc {
				width: inherit;
				flex: 1;
				margin-left: 20px;
			}
		}
	}

	.subpage-gallery .inside {
		margin-bottom: 0;
		.item {
			margin-bottom: 20px;
			a .photo {
				width: 335px;
				height: 335px;
			}
		}
	}

	.messages {
		width: 100%;
		font-size: 15px;
		line-height: 18px;
	}

	.product-inside {
		.top {
			flex-wrap: wrap;
			padding-bottom: 0;
			margin-bottom: 30px;
			.left-col {
				width: 100%;
				margin-bottom: 40px;
				top: inherit;
				position: inherit;
			}
			.right-col {
				width: 100%;
				margin-left: 0;
				h2.category {
					font-size: 24px;
				}
				h3.name {
					font-size: 22px;
					line-height: 26px;
				}
				.to-cart .btn-turquoise {
					min-width: 465px;
				}
			}
		}
		.describe {
			h4 {
				font-size: 18px;
			}
			p {
				font-size: 16px;
				line-height: 32px;
			}
		}
		.product-reviews {
			margin-top: 70px;
			.top {
				margin-bottom: 30px;
				h4 {
					font-size: 18px;
					line-height: 20px;
				}
				p.grades {
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
	}

	.chemex-product-photos .app-figure a.MagicZoom img {
		max-height: 513px !important;
	}

}

/*****************************************************************************
max-width: 767px
*****************************************************************************/
@media only screen and (max-width: 767px) {

	.how-many-new {
		margin-top: 10px;
		p {
			display: block;
		}
	}

	.popup {
		width: 80%;
		max-height: 80%;
		height: inherit;
		top: 40px;
		bottom: inherit;
		h3 {
			margin-bottom: 15px;
			font-size: 17px;
			line-height: 21px;
		}
	}

	#Header {
		.header-top .inside {
			justify-content: flex-end;
			.left-col {
				display: none;
			}
		}
		.header-middle .inside {
			.left-col a.logo {
				width: 134px;
				height: 56px;
			}
			.right-col {
				.form-place.active {
				}
				.actions-shop div {
					margin-left: 15px;
				}
			}
		}
	}

	#main-slider {
		.slider-main .slide {
			.content-wrapper {
				width: 510px;
			}
			.desc {
				margin-top: -30px;
				h3 {
					margin-bottom: 2px;
					font-size: 34px;
					line-height: 38px;
				}
				h2 {
					font-size: 40px;
					line-height: 50px;
					-webkit-text-stroke-width: 2px;
				}
				p {
					font-size: 16px;
					line-height: 22px;
				}
			}
		}
		.bx-controls {
			bottom: 10px;
			.bx-pager {
				margin-left: 290px;
			}
		}
	}

	#bestsellers-home {
	  margin-top: 40px;
		.products-slider {
			margin-top: 30px;
		}
	}

	#new-products-home {
		margin-bottom: 50px;
		.products-slider {
			margin-top: 30px;
		}
	}

	#specials-home {
		padding: 40px 0;
		.inside {
			flex-wrap: wrap;
			height: inherit;
			.left-col {
				width: 100%;
				min-height: 380px;
				margin: 0 0 30px;
				padding: 35px 0 30px 30px;
				.time .item {
					margin-right: 8px;
				}
				.btn-transparent-green {
					position: inherit;
					bottom: inherit;
				}

			}
			.right-col {
				width: 365px;
				height: 498px;
				margin: 0 auto;
			}
		}
	}

	#filter-home .text-line .btn-search {
		padding: 0 60px;
	}

	#category-main {
		height: 400px;
		margin-bottom: 60px;
		padding-top: 50px;
		&:before {
			height: 400px;
			width: 160px;
		}
		.inside {
			margin-bottom: 20px;
			.desc {
				left: -20px;
				top: 32px;
				width: 273px;
				height: inherit;
				padding: 30px 25px;
				h3 {
					font-size: 20px;
					line-height: 24px;
				}
				p {
					font-size: 14px;
					line-height: 24px;
				}
			}
		}
	}

	#inspirations-home {
		margin-bottom: 40px;
		.insta-info {
			margin-left: 20px;
			height: 30px;
			margin-left: 15px;
			padding-left: 40px;
			background-size: 30px;
			p {
				&.big {
					font-size: 16px;
					line-height: 16px;
				}
			}
		}
		.inside {
			.items {
				margin-top: 30px;
				flex-wrap: wrap;
			}
			.few-photos {
				width: 510px;
				a.insta-photo {
					width: 250px;
					height: 250px;
					margin-bottom: 12px;
				}
			}
			.one-photo {
				width: 510px;
				a.insta-photo {
					width: 510px;
					height: 100%;
				}
			}
		}
	}

	#blog-home .inside {
		padding-top: 40px;
		h2 {
			margin-bottom: 30px;
		}
		.items {
			margin-bottom: 20px;
			.item {
			  width: 250px;
				a {
					.photo {
						height: 166px;
					}
					.desc {
						padding: 10px 15px 0;
						time {
							font-size: 15px;
						}
						h3 {
							font-size: 18px;
						}
					}
				}
				&:last-child {
					display: none;
				}
			}
		}
	}

	#category-selected {
		margin-bottom: 40px;
		height: inherit;
		&:before {
			width: 100%;
			height: 65px;
		}
		.category-links {
			position: inherit;
			top: 0;
			padding-top: 16px;
			a:after {
				margin-top: 5px;
			}
		}
		.inside {
			flex-wrap: wrap;
			.left-col {
				width: 100%;
				h2 {
					margin-bottom: 20px;
				}
				.photo {
					margin-left: 0;
					width: 100%;
					height: inherit;
					margin-bottom: 15px;
				}
			}
			.right-col {
				width: 100%;
				padding: 20px 0 0;
				h6 {
					margin-bottom: 15px;
					font-size: 16px;
				}
				p {
					margin-bottom: 15px;
					font-size: 15px;
					line-height: 26px;
				}
			}
		}
	}

	#delivery-info {
		padding: 40px 0;
		.inside .items {
			flex-wrap: wrap;
			.item {
				width: 100%;
				margin-bottom: 25px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	#shipping-payment .inside {
		min-height: inherit;
		flex-wrap: wrap;
		.left-col {
			width: 100%;
			background: none;
			margin-bottom: 30px;
			p {
				margin-bottom: 20px;
			}
		}
		.right-col {
			width: 100%;
			padding-left: 0;
			.photo {
				&:last-child {
					margin-right: 0;
				}
				img {
					max-width: 90%;
				}
			}
		}
		p {
			margin: 0 0 20px;
		}
	}

	#Footer {
		.top-footer {
			padding: 50px 0 60px;
			.left-col {
				display: block;
				h4 {
					font-size: 16px;
					line-height: 28px;
				}
				.tel-box {
					padding: 0;
				}
			}
			.right-col {
				flex-wrap: wrap;
				justify-content: space-between;
				.item {
					width: 45%;
					padding-right: 0;
					margin-bottom: 20px;
				}
			}
		}
		.category-links .inside ul li {
			width: 50%;
		}
		.signature {
			.inside {
				justify-content: center;
			}
			p {
				text-align: center;
			}
		}
	}

	.products-slider {
		.item a {
			margin-right: 20px;
			.photo {
				height: 260px;
			}
			.desc {
				h5 {
					margin-bottom: 7px;
					font-size: 13px;
				}
				p.price {
					font-size: 16px;
					strong {
						font-size: 18px;
					}
				}
			}
		}
		.slick-arrow {
			top: 120px;
			&.slick-prev {
				left: -20px;
			}
			&.slick-next {
				right: -20px;
			}
		}
	}

	.shopping-cart__subpage {
		margin: 30px 0 10px;
		.inside {
			.left-col {
				.head h3 {
					font-size: 14px;
				}
				.under-cart {
					ul.simply-link {
						flex-wrap: wrap;
						li {
							margin-bottom: 5px;
						}
					}
					.buttons {
						flex-wrap: wrap;
						margin-top: 20px;
						p.info {
							order: 3;
							margin: 10px 0 0;
							width: 100%;
							text-align: right;
						}
						.btn-transparent-grey {
							order: 1;
						}
						.btn-turquoise {
							order: 2;
						}
					}
				}
			}
			.right-col .products-list__subpage {
				justify-content: space-around;
				.item {
					width: 205px;
					a {
						.photo {
							height: 295px;
						}
					}
				}
			}
		}
	}

	.cart-table .cart-row {
		flex-wrap: wrap;
		.cart-width-1 {
			width: 9%;
			margin-bottom: 15px;
		}
		.cart-width-2 {
			width: 91%;
			padding-right: 0;
			margin-bottom: 15px;
		}
		.cart-width-3 {
			width: 50%;
			padding-left: 46px;
		}
		.cart-width-4 {
			width: 50%;
		}
	}

	.subpage-breadcrumb ol li:after {
		margin: 0 7px 0 10px;
	}

	.products__subpage {
		h2.h2-sb {
			font-size: 22px;
			line-height: 24px;
		}
		.inside {
			flex-wrap: wrap;
			.left-col {
				width: 100%;
			}
			.right-col {
				width: 100%;
				h5.text {
					margin-top: 5px;
				}
			}
		}
		.products-list__subpage .item {
			width: 240px;
			a .photo {
				height: 340px;
			}
		}
		.text-line {
			flex-wrap: wrap;
		}
	}

	.products-slider__subpage {
		h3 {
			margin-bottom: 20px;
		}
		.products-slider .item {
			width: 214px;
			a {
				margin: 0 5px;
				.photo {
					height: 303px;
				}
			}
		}
	}

	.button-fast-filtering {
		display: block;
	}
	.product-list-filtering {
		display: none;
		&.active {
			display: block;
		}
		 fieldset {
			 margin-bottom: 25px;
		 }
	}

	.input-line {
		justify-content: flex-start;
		input[type="text"] {
			width: 140px;
		}
		span {
			margin: 0 20px;
		}
	}

	.filtration-results .item .btn {
		padding: 9px 35px 9px 7px;
		font-size: 12px;
		&:after {
			width: 12px;
			height: 12px;
			right: 8px;
		}
	}

	.listing-sort {
		width: 100%;
		margin: 15px 0 0 0;
		.bootstrap-select {
			.btn {
				height: 36px;
				padding-top: 10px;
			}
			.dropdown-menu {
				width: 100%;
			}
		}
		&.nr-in {
			margin-top: 0;
			.form-control {
				height: 30px;
			}
			.bootstrap-select .btn {
				height: 30px;
				padding: 8px 8px 0 0;
			}
		}
	}

	.comparison{
		&.one-product {
			width: 510px;
		}
		&.two-products {
	  	width: 620px;
		}
	}

	.blog__subpage #blog-home .inside {
		h2 {
			margin-bottom: 20px;
			font-size: 22px;
		}
		.items .item {
			width: 250px;
			margin-bottom: 30px;
			&:last-child {
				display: block;
			}
			a {
				.photo {
					height: 166px;
				}
				.desc {
					h3 {
						font-size: 17px;
					}
				}
			}
		}
	}

	.inspiration__subpage #inspirations-home .inside {
		.items {
			margin-top: 0;
		}
		.one-photo a.insta-photo {
			margin-bottom: 12px;
		}
	}

	.shopping-cart__subpage.step-2 {
		margin-bottom: 30px;
	}

	.end-of-shopping {
		h4.cart {
			font-size: 20px;
			line-height: 24px;
		}
		ul.simply-link {
			flex-wrap: wrap;
		}
	}

	.customer-panel__subpage .inside {
		flex-wrap: wrap;
		margin-top: 30px;
		.left-col {
			width: 100%;
			margin-bottom: 20px;
			padding: 25px 20px 25px;
			&:after {
				display: none;
			}
			.top {
				margin-bottom: 10px;
				padding: 9px 0 9px 72px;
				background-position: 0 0;
				text-align: left;
				min-height: 57px;
			}
			.options .item {
				a {
					padding: 15px 10px 15px;
				}
				.counter-number {
					top: 15px;
				}
			}
		}
		.right-col {
			width: 100%;
		}
	}

	.order-details .cart-table .cart-row .cart-width-4 {
		width: 50%;
	}

	.payment-delivery {
		.item .radio label {
			font-size: 15px;
		}
		.delivery-country {
			padding: 20px 20px 15px 25px;
			.text {
				flex-wrap: wrap;
				font-size: 15px;
				.flag {
					width: 100%;
					margin: 10px 0 0 0;
				}
			}
		}
	}

	.shopping-cart__subpage {
		h4.h4-cart {
			font-size: 16px;
		}
		&.step-2 {
			.top h2 {
				font-size: 18px;
			}
			.form-simple .form-check-input {
				margin-top: 2px;
			}
		}
	}

	#contact-page-map #map {
		height: 300px;
	}

	.subpage-contact {
		margin: 30px 0 40px;
		.inside .right-col {
			padding: 40px 30px;
			p {
				font-size: 16px;
			}
		}
	}

	.subpage-gallery .inside .item a .photo {
		width: 248px;
		height: 248px;
	}

	.product-inside {
		.describe {
			h4 {
				padding-bottom: 10px;
				font-size: 16px;
			}
			p {
				font-size: 15px;
				line-height: 24px;
			}
		}
		.product-reviews {
			margin-top: 60px;
			.top {
				margin-bottom: 25px;
				h4 {
					font-size: 16px;
					line-height: 18px;
				}
				p.grades {
					font-size: 12px;
					line-height: 14px;
				}
			}
			.inside .left-col .item {
				margin-bottom: 25px;
				padding-bottom: 20px;
				p {
					font-size: 14px;
					line-height: 26px;
				}
				time {
					font-size: 14px;
				}
			}
		}
	}

	.product-inside .top .right-col {
		h3.name {
			font-size: 20px;
		}
		.to-cart .btn-turquoise {
			min-width: 300px;
		}
	}

}

/*****************************************************************************
max-width: 575px
*****************************************************************************/
@media only screen and (max-width: 575px) {

	.lang-box {
		top: 20px;
		padding: 30px 25px 25px;
		.language-list li a {
			font-size: 13px;
		}
		.inside {
			p {
				font-size: 13px;
			}
			.right-col h5 {
				margin-bottom: 10px;
			}
		}
	}

	#Header {
		.header-top .inside .right-col ul li {
			margin-left: 20px;
		}
		.header-middle .inside {
			.left-col a.logo {
				width: 134px;
				height: 56px;
			}
			.right-col .actions-shop div {
				width: 30px;
				height: 23px;
				margin-left: 15px;
				a {
					text-indent: -9999px;
				}
			}
		}
	}

	#Header.sticky .header-middle .inside .right-col .form-place.active {
		top: 63px;
	}

	#nav-icon {
	  margin: 0 0 0 10px;
	}

	#main-slider {
		.slider-main .slide {
			.content-wrapper {
				width: 100%;
				padding: 0 15px;
			}
			.desc {
				margin-top: -30px;
				h3 {
					margin-bottom: 2px;
					font-size: 30px;
					line-height: 34px;
				}
				h2 {
					font-size: 36px;
					line-height: 46px;
					-webkit-text-stroke-width: 2px;
				}
				p {
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
		.bx-controls {
			bottom: 10px;
			.bx-pager {
				margin-left: 250px;
			}
		}
	}

	#bestsellers-home {
		margin-bottom: 50px;
		.text-line a.link-simple {
			display: none;
		}
	}

	#new-products-home .text-line a.link-simple {
		display: none;
	}

	#specials-home .inside .left-col {
		height: 500px;
		.btn-transparent-green {
			position: absolute;
			bottom: 30px;
		}
	}

	#filter-home .filter-home-inside {
		margin-bottom: 15px;
		.item {
			margin-right: 24px;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}

	#category-main {
		height: inherit;
		padding-top: 0;
		&:before {
			display: none;
		}
		&:after {
			display: none;
		}
		.inside {
			.desc {
				position: inherit;
				left: 0;
				top: 0;
				width: 100%;
			}
		}
	}

	#inspirations-home {
		margin-bottom: 40px;
		.insta-info {
			margin-left: 20px;
			height: 30px;
			margin-left: 15px;
			padding-left: 40px;
			background-size: 30px;
			p {
				&.big {
					font-size: 16px;
					line-height: 16px;
				}
			}
		}
		.inside {
			a.insta-photo .effect {
				width: 70px;
				height: 70px;
			}
			.items {
				margin-top: 30px;
				justify-content: center;
			}
			.few-photos {
				width: 450px;
				a.insta-photo {
					width: 219px;
					height: 219px;
					margin-bottom: 12px;
				}
			}
			.one-photo {
				width: 450px;
				a.insta-photo {
					width: 450px;
				}
			}
		}
	}

	#blog-home .inside .items {
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 0;
		.item {
			width: 400px;
			margin-bottom: 30px;
			a {
				.photo {
					height: 265px;
				}
			}
		}
	}

	.products-slider {
		.item a {
			margin-right: 15px;
			.photo {
				height: 250px;
			}
			.desc {
				h5 {
					font-size: 12px;
				}
			}
		}
		.slick-arrow.slick-prev {
			left: -15px;
		}
		.slick-arrow.slick-next {
			right: -15px;
		}
	}

	.shopping-cart__subpage .inside {
		.left-col .head h3 {
			margin-right: 0;
			width: 100%;
			&.inactive {
				display: none;
			}
		}
		.right-col h4 {
			font-size: 15px;
		}
	}

	.cart-table .cart-row {
		padding: 25px 0;
		.quantity {
			button {
				width: 35px;
				&.minus span, &.plus span {
					background-size: 16px auto;
				}
			}
			input.text {
				width: 55px;
				padding: 5px 0;
			}
		}
	}

	.products__subpage {
		margin-bottom: 30px;
		.products-list__subpage {
			justify-content: center;
			.item {
				width: 216px;
				margin-right: 18px;
				margin-bottom: 30px;
				&:nth-child(2n) {
					margin-right: 0;
				}
				a {
					.photo {
						height: 306px;
						margin-bottom: 10px;
					}
					.desc {
						h5 {
							margin-bottom: 5px;
							font-size: 13px;
							line-height: 17px;
						}
						p.price {
							font-size: 15px;
							line-height: 18px;
							strong {
								font-size: 18px;
							}
							s {
								font-size: 15px;
							}
							.red {
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}

	.products-slider__subpage {
		margin-bottom: 40px;
		.products-slider .item {
			width: 185px;
			a {
				.photo {
					margin-bottom: 10px;
					height: 250px;
				}
				.desc {
					p.price {
						font-size: 15px;
						line-height: 18px;
						strong {
							font-size: 18px;
						}
						s {
							font-size: 15px;
						}
					}
				}
			}
		}
	}

	.additional-text-product h3 {
		font-size: 16px;
		line-height: 20px;
	}

	.comparison.one-product {
	  width: 450px;
	}

	.blog__subpage #blog-home .inside {
		h2 {
			font-size: 20px;
		}
		.items {
			justify-content: center;
			.item {
				width: 220px;
				margin-right: 10px;
				&:nth-child(2n) {
					margin-right: 0;
				}
				a {
					.photo {
						height: 146px;
					}
					.desc {
						padding: 8px 0 0;
						time {
							margin-bottom: 2px;
						}
					}
				}
			}
		}
	}

	.map-site__subpage .text-line {
	  margin-bottom: 20px;
	}

	.shopping-cart__subpage.step-2 .inside .right-col h5 {
		font-size: 18px;
	}

	.shopping-cart__subpage.step-2 .inside .left-col .head {
		h3.active:first-child, h3.active:nth-child(3) {
			display: none;
		}
	}

	.shopping-cart__subpage.step-3 .inside {
		.left-col .head {
			h3.active:first-child, h3.active:nth-child(2) {
				display: none;
			}
		}
		.right-col {
			padding-bottom: 15px;
		}
	}

	.end-of-shopping {
		padding-top: 0;
	}

	.customer-panel__subpage .inside .left-col {
		padding-bottom: 15px;
	}

	.subpage-contact .inside {
		.left-col {
			h3 {
				font-size: 18px;
			}
			p {
				font-size: 14px;
			}
		}
		.right-col {
			h4, h5 {
				font-size: 16px;
			}
			p {
				margin-bottom: 30px;
				font-size: 15px;
			}
		}
	}

	.subpage-gallery .inside {
		justify-content: center;
		.item {
			margin-bottom: 15px;
			margin-right: 15px;
			&:nth-child(3n) {
				margin-right: 15px;
			}
			&:nth-child(2n) {
				margin-right: 0;
			}
			a .photo {
				width: 217px;
				height: 217px;
			}
		}
	}

	.messages {
		font-size: 14px;
		line-height: 16px;
	}

	.product-inside {
		margin: 35px 0 60px;
		.top .right-col {
			h2.category {
				font-size: 22px;
			}
			h3.name {
				font-size: 18px;
				line-height: 24px;
			}
			.to-cart .btn-turquoise {
				min-width: inherit;
			}
		}
	}

	.chemex-product-photos .app-figure {
		a.MagicZoom img {
			max-height: 437px !important;
		}
		.selectors {
			width: 100px;
			.mz-thumb {
				width: 100px;
				height: 100px;
			}
			.slick-arrow {
				top: 450px;
				&.slick-next {
						left: 10px;
				}
				&.slick-prev {
						right: 10px;
				}
			}
		}
	}

}

/*****************************************************************************
max-width: 479px
*****************************************************************************/
@media only screen and (max-width: 479px) {

	.choose-size .btn-light {
		padding-left: 20px;
	}
	.choose-size .dropdown-menu li a {
		padding: 10px 15px;
	}
	.choose-size .carpet_size {
		width: inherit;
		margin-right: 20px;
	}

	.popup {
		width: 90%;
		top: 30px;
		padding: 35px 25px 40px;
		h3 {
			font-size: 16px;
		}
	}

	.lang-box {
		.language-list li {
			width: 100%;
		}
	}

	#Header, #Header.sticky {
		.header-middle .inside {
			.left-col {
				a.logo {
				  width: 120px;
				  height: 50px;
				}
				.behind {
					right: -21px;
					width: 40px;
					height: 28px;
					background-size: 100%;
				}
			}
			.right-col {
				.form-place input[type="text"], .form-place.active input[type="text"] {
					width: 320px;
					padding: 0 15px;
				}
				.actions-shop div {
					margin-left: 10px;
					&:first-child {
						margin-left: 10px;
					}
				}
			}
		}
	}

	#main-slider {
		.slider-main .slide {
			.desc {
				margin-top: -30px;
				h3 {
					margin-bottom: 0;
					font-size: 26px;
					line-height: 30px;
				}
				h2 {
					font-size: 34px;
					line-height: 44px;
					-webkit-text-stroke-width: 2px;
				}
				p {
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
		.bx-controls {
			bottom: 10px;
			.bx-pager {
				margin-left: 220px;
			}
		}
	}

	#bestsellers-home {
		margin: 30px 0 40px;
		.products-slider {
			margin-top: 20px;
		}
	}

	#new-products-home .products-slider {
		margin-top: 20px;
	}

	#specials-home {
		padding: 30px 0 35px;
		.inside .left-col {
			height: 655px;
			.discount {
				width: 100px;
				p.text {
					font-size: 15px;
				}
				p.number {
					font-size: 22px;
				}
			}
			h3 {
				font-size: 28px;
			}
			h5 {
				font-size: 16px;
			}
			p.price {
				font-size: 15px;
			}
			.time .item {
				height: 66px;
				p.number {
					font-size: 22px;
					line-height: 22px;
				}
			}
			.photo-product {
				max-width: 100%;
				max-height: 472px;
			}
			.btn-transparent-green {
				position: inherit;
				bottom: inherit;
			}
		}
	}

	#filter-home {
		h4 {
			display: none;
		}
		.filter-home-inside {
			justify-content: center;
			.item {
				margin: 0 10px 30px;
				&:nth-child(2n) {
					margin-right: 10px;
				}
			}
		}
		.text-line .btn-search {
			padding: 0 50px;
		}
	}

	#category-main {
		margin-bottom: 50px;
	}

	#inspirations-home {
		margin-bottom: 30px;
		.text-line {
			flex-wrap: wrap;
		}
		h6.h6-main {
			display: none;
		}
		.insta-info {
			margin-left: 0;
			width: 100%;
			margin-top: 15px;
		}
		.inside {
			a.insta-photo .effect {
				width: 70px;
				height: 70px;
			}
			.items {
				margin-top: 20px;
				justify-content: center;
			}
			.few-photos {
				width: 390px;
				a.insta-photo {
					width: 190px;
					height: 190px;
					margin-bottom: 11px;
				}
			}
			.one-photo {
				width: 390px;
				a.insta-photo {
					width: 390px;
				}
			}
		}
	}

	#blog-home {
		.inside {
			h2 {
				margin-bottom: 25px;
			}
			.items .item {
				width: 100%;
				a {
					.photo {
						height: inherit;
					}
				}
			}
		}
	}

	#category-selected {
		&:before {
			height: 140px;
		}
		.category-links a {
			display: block;
			margin-bottom: 9px;
		}
	}

	#Footer {
		.top-footer {
			padding: 50px 0;
			text-align: center;
			.right-col .item {
				width: 100%;
			}
		}
		.category-links .inside ul li {
			width: 100%;
		}
	}

	.shopping-cart__subpage {
		margin: 25px 0 5px;
		.inside {
			.left-col {
				.head {
					margin-bottom: 0;
				}
				.under-cart .whole-value p.number {
					margin-left: 30px;
				}
			}
			.right-col {
				h4 {
					margin-bottom: 20px;
				}
				.products-list__subpage .item {
					width: 180px;
					a {
						.photo {
							height: 256px;
							margin-bottom: 10px;
						}
						.desc h5 {
							margin-bottom: 5px;
						}
					}
				}
			}
		}
	}

	.cart-table .cart-row {
		.cart-width-1 {
			width: 11%;
		}
		.cart-width-2 {
			width: 89%;
		}
		.cart-width-3 {
			padding-left: 43px;
		}
		.name-product .photo {
			margin-right: 15px;
		}
		.quantity {
			button {
				width: 35px;
				&.minus span, &.plus span {
					background-size: 11px auto;
				}
			}
			input.text {
				width: 45px;
				padding: 3px 0;
			}
		}
	}

	.products__subpage {
		margin-bottom: 30px;
		.products-list__subpage {
			.item {
				width: 187px;
				margin-right: 15px;
				margin-bottom: 30px;
				a {
					.photo {
						height: 265px;
					}
					.desc {
						h5 {
							margin-bottom: 5px;
							font-size: 13px;
							line-height: 17px;
						}
						p.price {
							font-size: 14px;
							line-height: 18px;
							strong {
								font-size: 16px;
							}
							s {
								font-size: 14px;
							}
							.red {
								font-size: 15px;
							}
						}
					}
				}
			}
		}
	}

	.products-slider__subpage {
		margin-bottom: 40px;
		.products-slider {
			.item {
				width: 154px;
				a {
					.photo {
						margin-bottom: 10px;
						height: 205px;
					}
					.desc {
						p.price {
							font-size: 12px;
							line-height: 18px;
							strong {
								font-size: 12px;
							}
							s {
								font-size: 12px;
							}
							.red {
								font-size: 12px;
							}
						}
					}
				}
			}
			.slick-arrow.slick-prev, .slick-arrow.slick-next {
				top: 80px;
			}
		}
	}

	.comparison.one-product {
		width: 390px;
	}

	.blog__subpage #blog-home {
		margin-bottom: 20px;
		.inside {
			h2 {
				font-size: 20px;
			}
			.items {
				justify-content: center;
				.item {
					width: 190px;
					a {
						.photo {
							height: 126px;
						}
						.desc {
							padding: 8px 0 0;
							time {
								font-size: 13px;
							}
							h3 {
								font-size: 15px;
							}
						}
					}
				}
			}
		}
	}

	.subpage .text-line h2.h2-sb {
		font-size: 24px;
	}
	.inspiration__subpage #inspirations-home .insta-info {
		margin-bottom: 15px;
	}

	.shopping-cart__subpage.step-2 .inside .right-col .buttons {
		flex-wrap: wrap;
		.btn {
			width: 100%;
			&.btn-transparent-grey {
				order: 2;
			}
			&.btn-turquoise {
				order: 1;
				margin-bottom: 10px;
			}
		}
	}

	.shopping-cart__subpage.step-3 .inside .right-col {
		padding-bottom: 15px;
	}

	.end-of-shopping {
		h4.cart {
			font-size: 18px;
		}
		ul.simply-link {
			flex-direction: column;
		}
	}

	.discount-code {
		flex-wrap: wrap;
		p {
			width: 100%;
			margin-bottom: 10px;
		}
		input.form-control {
			margin-left: 0;
		}
	}

	.subpage-gallery .inside .item a .photo {
	  width: 187px;
	  height: 187px;
	}

	.subpage-blog-details {
		.blog-details .blog-details__header h3 {
			font-size: 16px;
			line-height: 18px;
		}
		.blog-sidebar {
			aside h3 {
				margin-bottom: 25px;
				font-size: 14px;
				line-height: 18px;
			}
			.widget-latest-news .items .item a .desc {
				margin-left: 12px;
			}
		}
	}

	.product-inside {
		margin: 35px 0 60px;
		.describe {
			h4 {
				margin-bottom: 15px;
				font-size: 15px;
				line-height: 19px;
			}
			p {
				font-size: 14px;
			}
		}
		.product-reviews {
			.top h4 {
				font-size: 15px;
				line-height: 19px;
			}
			.inside {
				.left-col .item {
					margin-bottom: 20px;
					padding-bottom: 12px;
				}
				.right-col h5 {
					font-size: 16px;
				}
			}
		}
	}

	.product-inside {
		.top .right-col {
			h2.category {
				font-size: 20px;
			}
			h3.name {
				font-size: 16px;
				line-height: 22px;
			}
			.variants-color .items .item {
				margin-right: 10px;
			}
			.to-cart {
				flex-wrap: wrap;
				.quantity {
					width: 100%;
					margin-bottom: 10px;
				}
			}
			.detailed {
				padding-left: 0;
			}
		}
		.product-reviews .inside .right-col .rate {
			flex-wrap: wrap;
			.rating {
				margin-bottom: 15px;
			}
			.btn-turquoise {
				width: 100%;
			}
		}
	}

	.chemex-product-photos .app-figure {
		a.MagicZoom img {
			max-height: 357px !important;
		}
		.selectors {
			width: 80px;
			margin-right: 15px;
			.mz-thumb {
				width: 80px;
				height: 80px;
			}
			.slick-arrow {
				top: 368px;
				&.slick-next {
						left: 5px;
				}
				&.slick-prev {
						right: 5px;
				}
			}
		}
	}

}

/*****************************************************************************
max-width: 479px
*****************************************************************************/
@media only screen and (max-width: 419px) {

	.popup {
		top: 20px;
		padding: 35px 20px 30px;
	}

	.lang-box .inside .right-col {
		.item-select {
			margin: 25px 0 10px;
		}
		.btn-turquoise {
			width: 100%;
		}
	}

	#additional-menu .additional-menu__responsive {
		.additional-menu__top .social {
			margin-left: 20px;
			a {
				margin-left: 10px;
			}
		}
		.additional-menu__bottom {
			display: flex;
		}
	}

	.side-panel {
		width: 300px;
		right: -300px;
		padding: 40px 25px 44px;
	}

	.user-panel {
		.top {
			padding: 6px 0 6px 58px;
			background-size: 50px;
			h5 {
				font-size: 15px;
			}
			p {
				font-size: 12px;
			}
		}
		.options .item {
			a {
				padding: 18px 14px;
			}
			.counter-number {
				top: 17px;
			}
		}
	}

	.user-login .inside .buttons {
		flex-wrap: wrap;
		.btn {
			width: 100%;
			&.btn-transparent-grey {
				order: 2;
			}
			&.btn-turquoise {
				order: 1;
				margin-bottom: 10px;
			}
		}
	}

	.user-registration .inside {
		.items .item.item-group {
			flex-wrap: wrap;
			.form-control {
				width: 100%;
				margin-bottom: 10px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.buttons {
			flex-wrap: wrap;
			.btn {
				width: 100%;
				&.btn-transparent-grey {
					order: 2;
				}
				&.btn-turquoise {
					order: 1;
					margin-bottom: 10px;
				}
			}
		}
	}

	.minicart .inside {
		.total-price {
			flex-wrap: wrap;
			justify-content: end;
			margin-bottom: 15px;
			p.text {
				width: 100%;
				margin: 0 0 5px;
				text-align: right;
			}
		}
		.buttons {
			flex-wrap: wrap;
			.btn {
				width: 100%;
				&.btn-transparent-grey {
					order: 2;
				}
				&.btn-turquoise {
					order: 1;
					margin-bottom: 10px;
				}
			}
		}
		.minicart-bottom {
			left: 25px;
			right: 25px;
			bottom: 25px;
		}
	}

	#Header, #Header.sticky {
		height: 120px;
		.header-middle .inside {
			height: 80px;
			.left-col {
				a.logo {
					width: 100px;
					height: 41px;
				}
				.behind {
					width: 30px;
					height: 21px;
					right: -16px;
				}
			}
			.right-col {
				.actions-shop div {
					margin-left: 8px;
					&:first-child {
						margin-left: 12px;
					}
					&.wishlist-link {
						display: none;
					}
					&.compare-link {
						display: none;
					}
				}
				.form-place {
					margin-right: 10px;
					&.active {
						top: 125px;
						margin-right: 10px;
						input[type="text"] {
							width: 250px;
							padding: 0 10px;
						}
					}
					input[type="text"] {
						width: 250px;
						padding: 0 10px;
					}
					a.search-loupe {
						width: 50px;
					}
				}
			}
		}
	}
	#Header.sticky {
		height: 80px;
		.header-middle .inside .right-col .form-place.active {
			top: 56px;
			margin-right: -5px;
		}
	}

	#Content {
		padding-top: 120px;
	}
	#Content.sticky {
		padding-top: 80px;
	}

	#main-slider {
		.slider-main .slide {
			.desc {
				margin-top: 0;
				h3 {
					font-size: 22px;
					line-height: 26px;
				}
				h2 {
					font-size: 30px;
					line-height: 40px;
					-webkit-text-stroke-width: 2px;
				}
				p {
					display: none;
				}
			}
		}
		.bx-controls {
			bottom: 10px;
			.bx-pager {
				margin-left: 170px;
			}
		}
	}

	#specials-home .inside {
		.left-col {
			height: 570px;
			padding: 60px 20px 20px;
			h6 {
				font-size: 16px;
			}
			h3 {
				font-size: 26px;
			}
			.photo-product {
				max-height: 254px;
			}
		}
		.right-col {
			height: 420px;
		}
	}

	#filter-home {
		.text-line .btn-search {
			padding: 0 35px;
			line-height: 45px;
			font-size: 15px;
		}
		.filter-home-inside {
			margin-bottom: 0;
		}
	}

	#category-main {
		margin-bottom: 40px;
		a.link {
			font-size: 13px;
			padding-right: 20px;
		}
	}

	#inspirations-home {
		.inside {
			a.insta-photo .effect {
				width: 70px;
				height: 70px;
			}
			.items {
				margin-top: 20px;
			}
			.few-photos {
				width: 100%;
				a.insta-photo {
					width: 100%;
					height: inherit;
					margin-bottom: 11px;
					img {
						margin: 0 auto;
					}
				}
			}
			.one-photo {
				width: 100%;
				a.insta-photo {
					width: 100%;
				}
			}
		}
	}

	.shopping-cart__subpage .inside {
		.left-col .under-cart {
			.whole-value p.number {
				margin-left: 20px;
				font-size: 18px;
			}
			.buttons {
				p.info {
					text-align: center;
				}
				.btn {
					width: 100%;
					&.btn-transparent-grey {
						margin-right: 0;
						order: 2;
					}
					&.btn-turquoise {
						order: 1;
						margin-bottom: 10px;
					}
				}
			}
			ul.simply-link {
				li {
					width: 100%;
					margin-right: 0;
					a {
						text-align: center;
					}
				}
			}
		}
		.right-col .products-list__subpage .item {
			width: 135px;
			margin-bottom: 25px;
			a {
				.circles {
					right: 7px;
					div {
						margin-bottom: 10px;
					}
				}
				.photo {
					height: 192px;
					margin-bottom: 7px;
				}
				.desc {
					h5 {
						margin-bottom: 2px;
						font-size: 12px;
						line-height: 16px;
					}
					p.price {
						font-size: 14px;
						strong {
							font-size: 14px;
						}
					}
				}
			}
		}
	}

	.cart-table .cart-row {
		.cart-width-1 {
			width: 12%;
		}
		.cart-width-2 {
			width: 88%;
		}
		.cart-width-3 {
			padding-left: 0;
		}
		.remove a {
			width: 25px;
			height: 25px;
		}
		.name-product .photo {
			margin-right: 10px;
		}
		.quantity {
			button {
				width: 33px;
			}
			input.text {
				width: 42px;
				font-size: 16px;
			}
		}
	}

	.products__subpage {
		margin-bottom: 20px;
		h2.h2-sb {
			font-size: 18px;
			line-height: 22px;
		}
		.products-list__subpage {
			.item {
				width: 140px;
				margin-right: 10px;
				margin-bottom: 20px;
				a {
					.photo {
						height: 198px;
						margin-bottom: 7px;
					}
					.desc {
						h5 {
							margin-bottom: 5px;
							font-size: 12px;
							line-height: 15px;
						}
						p.price {
							font-size: 12px;
							line-height: 16px;
							strong {
								font-size: 14px;
							}
							s {
								font-size: 12px;
							}
							.red {
								font-size: 14px;
							}
						}
					}
					.circles {
						right: 10px;
						top: 14px;
						div {
							width: 30px;
							height: 30px;
							margin-bottom: 10px;
						}
					}
				}
			}
		}
	}

	.products-slider__subpage {
		margin-bottom: 30px;
		h3 {
			font-size: 15px;
			line-height: 19px;
		}
		.products-slider {
			.item {
				width: 210px;
				a {
					.photo {
						margin-bottom: 10px;
						height: 284px;
					}
				}
			}
			.slick-arrow.slick-prev, .slick-arrow.slick-next {
				top: 125px;
			}
		}
	}

	.additional-text-product {
		h3 {
			margin-bottom: 12px;
			font-size: 14px;
			line-height: 18px;
		}
		p {
			font-size: 13px;
			line-height: 23px;
		}
	}

	.subpage-breadcrumb ol li {
		font-size: 12px;
		&:after {
			margin: 0 5px 0 8px;
		}
	}

	.blog__subpage #blog-home .inside .items .item {
		margin-right: 0;
		width: 290px;
		a {
			.photo {
				height: 193px;
			}
		}
	}

	.subpage .text-line h2.h2-sb {
		font-size: 22px;
	}

	.shopping-cart__subpage.step-2 .inside .right-col {
		padding: 30px 25px;
		h5 {
			font-size: 16px;
			line-height: 19px;
		}
		.regulations {
			margin-bottom: 30px;
		}
	}

	.price-summary .price-summary__row.all {
		flex-direction: column;
		align-items: end;
		.txt {
			margin-bottom: 8px;
		}
	}

	.shopping-cart__subpage.step-3 .inside .right-col {
		padding-bottom: 0;
	}

	.customer-panel__subpage .inside {
		margin-top: 20px;
		.left-col {
			padding: 20px 15px 10px;
			.top {
				padding: 0 0 0 40px;
				min-height: inherit;
				background-size: 30px;
				background-position: 0 center;
			}
		}
		.right-col {
			padding-top: 0;
			h3 {
				margin-bottom: 17px;
				padding-bottom: 8px;
				font-size: 15px;
			}
			p.intro {
				margin-bottom: 15px;
				font-size: 13px;
			}
			.top {
				h2 {
					font-size: 15px;
				}
				p.date {
					font-size: 12px;
				}
			}
		}
	}

	.order-list {
		margin-top: 0;
	}

	.order-details {
		.payment .text p {
			font-size: 14px;
		}
		.whole-value {
			p.text {
				font-size: 14px;
			}
			p.number {
				margin-left: 10px;
				font-size: 17px;
			}
		}
		.address .item {
			margin-bottom: 20px;
			h5 {
				margin-bottom: 10px;
			}
			p {
				font-size: 14px;
			}
		}
	}

	.changed-password .buttons {
		flex-wrap: wrap;
		.btn {
			width: 100%;
			&.btn-transparent-grey {
				order: 2;
			}
			&.btn-turquoise {
				margin-left: 0;
				margin-bottom: 10px;
				order: 1;
			}
		}
	}

	.changed-data {
		.client_company {
			.form-row-ad {
					flex-wrap: wrap;
					.form-group {
						width: 100%;
					}
			}
		}
		textarea.form-control {
			height: 100px;
		}
		.buttons {
			flex-wrap: wrap;
			.btn {
				width: 100%;
				&.btn-transparent-grey {
					order: 2;
				}
				&.btn-turquoise {
					margin-left: 0;
					margin-bottom: 10px;
					order: 1;
				}
			}
		}
	}
	.vat-eu {
		width: 100%;
		.best-checkbox {
			width: 49%;
		}
		.nip-kod {
			width: 49%;
			.form-group {
				margin-left: 0;
			}
		}
	}

	.shopping-cart__subpage.step-2 .changed-data p {
		width: 100%;
	}

	.payment-delivery .delivery-country {
		margin-top: 30px;
	}

	.discount-code {
		input.form-control {
			width: 100%;
			margin-right: 0;
			margin-bottom: 10px;
		}
		.btn-transparent-turquoise {
			width: 100%;
		}
	}

	#contact-page-map #map {
	  height: 250px;
	}

	.subpage-contact .inside .right-col {
		padding: 30px 20px;
		h4, h5 {
			font-size: 15px;
		}
		p {
			font-size: 14px;
		}
	}

	.subpage-gallery .inside .item {
		margin-right: 0;
		&:nth-child(3n) {
			margin-right: 0;
		}
		a .photo {
			width: 100%;
			height: inherit;
		}
	}

	.messages {
		margin-bottom: 15px;
		font-size: 13px;
		line-height: 16px;
	}

	.product-inside {
		margin: 25px 0 50px;
		.top .right-col h2.category {
			font-size: 18px;
		}
		.product-reviews {
			margin-top: 40px;
			.top {
				flex-wrap: wrap;
				padding-bottom: 10px;
				h4 {
					margin-bottom: 8px;
				}
			}
			.inside {
				.left-col .item .who {
					min-height: 48px;
					padding-left: 63px;
					background-size: 50px;
					h5 {
						margin-top: 0;
					}
				}
				.right-col {
					h5 {
						font-size: 15px;
					}
				}
			}
		}
	}

	.chemex-product-photos .app-figure {
		a.MagicZoom img {
			max-height: 277px !important;
		}
		.selectors {
			width: 60px;
			margin-right: 15px;
			.mz-thumb {
				width: 60px;
				height: 60px;
			}
			.slick-arrow {
				top: 290px;
				&.slick-next {
						left: 0;
				}
				&.slick-prev {
						right: 0;
				}
			}
		}
	}

	.choose-size .carpet_availability {
		display: none;
	}

}
